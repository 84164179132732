var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v(_vm._s(_vm.$t("ContractManagement")))]
            )
          ]),
          [
            _c(
              "avue-crud",
              {
                attrs: {
                  option: _vm.setData.tableOpt,
                  data: _vm.tableData,
                  "row-style": _vm.rowStyle,
                  page: _vm.page
                },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "current-change": _vm.currentChange
                },
                scopedSlots: _vm._u([
                  {
                    key: "menu",
                    fn: function(ref) {
                      var row = ref.row
                      var index = ref.index
                      var size = ref.size
                      var type = ref.type
                      return [
                        (row.Status == 0
                        ? true
                        : false)
                          ? _c("el-button", {
                              attrs: {
                                icon: "el-icon-edit-outline",
                                type: "text",
                                size: "medium",
                                title: _vm.$t("Edit")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.editInfo(row, index, size, type)
                                }
                              }
                            })
                          : _vm._e(),
                        (row.Status == 0
                        ? true
                        : false)
                          ? _c("el-button", {
                              attrs: {
                                icon: "el-icon-delete-solid",
                                type: "text",
                                size: "medium",
                                title: _vm.$t("Delete")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delInfo(row, index)
                                }
                              }
                            })
                          : _vm._e(),
                        (row.Status == 1
                        ? true
                        : false)
                          ? _c("el-button", {
                              attrs: {
                                icon: "el-icon-refresh-left",
                                type: "text",
                                size: "medium",
                                title: _vm.$t("RecoverData")
                              },
                              on: {
                                click: function($event) {
                                  return _vm.recover(row, index)
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "expand",
                    fn: function(props) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: props.row.ContractDetails,
                              "header-cell-style": _vm.tableHeaderColor
                            }
                          },
                          [
                            _c("el-table-column", { attrs: { type: "index" } }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Code",
                                label: _vm.$t("ProductCode")
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "ProductName",
                                label: _vm.$t("Product Name")
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "Number", label: _vm.$t("Number") }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "UnitName", label: _vm.$t("UNIT") }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "Color", label: _vm.$t("COLOR") }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "Remark", label: _vm.$t("REMARK") }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "template",
                  {
                    staticStyle: { width: "calc(100% - 80px)" },
                    slot: "menuRight"
                  },
                  [
                    _c(
                      "el-row",
                      { staticClass: "topFromSearch", attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "fromOutDiv",
                            attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("ContractNo")) + ":")
                                ]),
                                _c("el-input", {
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.contractNoVal,
                                    callback: function($$v) {
                                      _vm.contractNoVal = $$v
                                    },
                                    expression: "contractNoVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("ContractCategory")) + ":"
                                  )
                                ]),
                                _c("el-input", {
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.categoryVal,
                                    callback: function($$v) {
                                      _vm.categoryVal = $$v
                                    },
                                    expression: "categoryVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("Product Name")) + ":")
                                ]),
                                _c("el-input", {
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.productVal,
                                    callback: function($$v) {
                                      _vm.productVal = $$v
                                    },
                                    expression: "productVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", { attrs: { id: "venSpan" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("CooperativeCompany")) + ":"
                                  )
                                ]),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      multiple: "",
                                      "collapse-tags": "",
                                      placeholder: "",
                                      clearable: "true"
                                    },
                                    on: {
                                      focus: function($event) {
                                        return _vm.focusDiv($event)
                                      },
                                      blur: function($event) {
                                        return _vm.blurDiv($event)
                                      },
                                      change: _vm.venchang
                                    },
                                    model: {
                                      value: _vm.cooperativeVal.ids,
                                      callback: function($$v) {
                                        _vm.$set(_vm.cooperativeVal, "ids", $$v)
                                      },
                                      expression: "cooperativeVal.ids"
                                    }
                                  },
                                  _vm._l(this.cooperativeData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("EffectiveStartDate")) + ":"
                                  )
                                ]),
                                _c("el-date-picker", {
                                  staticClass: "dataicon",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    "prefix-icon": ""
                                  },
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.beginTimeVal,
                                    callback: function($$v) {
                                      _vm.beginTimeVal = $$v
                                    },
                                    expression: "beginTimeVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("EffectiveEndDate")) + ":"
                                  )
                                ]),
                                _c("el-date-picker", {
                                  staticClass: "dataicon",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    "prefix-icon": ""
                                  },
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.endTimeVal,
                                    callback: function($$v) {
                                      _vm.endTimeVal = $$v
                                    },
                                    expression: "endTimeVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("ProductCode")) + ":")
                                ]),
                                _c("el-input", {
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.codetVal,
                                    callback: function($$v) {
                                      _vm.codetVal = $$v
                                    },
                                    expression: "codetVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                staticStyle: {
                                  "justify-content": "flex-start",
                                  height: "38px",
                                  "align-items": "center",
                                  display: "flex"
                                },
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass: "othChe",
                                    model: {
                                      value: _vm.StatusVal,
                                      callback: function($$v) {
                                        _vm.StatusVal = $$v
                                      },
                                      expression: "StatusVal"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("DeletedData")))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "padding-top": "10px" },
                            attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                          },
                          [
                            _c("el-button", {
                              staticClass: "moreBtn",
                              staticStyle: { "margin-right": "0" },
                              attrs: {
                                size: "small",
                                icon: "iconfont icon-24px",
                                circle: ""
                              },
                              on: { click: _vm.moreFrom }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  plain: ""
                                },
                                on: { click: _vm.searchFrom }
                              },
                              [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-plus"
                                },
                                on: { click: _vm.addInfo }
                              },
                              [_vm._v(_vm._s(_vm.$t("ADD")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }