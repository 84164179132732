<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{$t('ContractManagement')}}</div>
            </div>
            <template>
                <avue-crud :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange">
                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('ContractNo')}}:</span>
                                    <el-input v-model="contractNoVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('ContractCategory')}}:</span>
                                    <el-input v-model="categoryVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('Product Name')}}:</span>
                                    <el-input v-model="productVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span id="venSpan">{{$t('CooperativeCompany')}}:</span>
                                    <el-select  v-model="cooperativeVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;"  @change="venchang" clearable="true">
                                        <el-option v-for="item in this.cooperativeData"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('EffectiveStartDate')}}:</span>
                                    <el-date-picker class="dataicon" v-model="beginTimeVal"
                                                    type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                    </el-date-picker>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('EffectiveEndDate')}}:</span>
                                    <el-date-picker class="dataicon" v-model="endTimeVal"
                                                    type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                    </el-date-picker>
                                </el-col>
                                <!--<el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('Brand')}}:</span>
                                    <el-input v-model="brandVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>-->
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('ProductCode')}}:</span>
                                    <el-input v-model="codetVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6"  style="justify-content:flex-start;height:38px;align-items:center;display:flex;">
                                    <el-checkbox v-model="StatusVal" class="othChe">{{$t('DeletedData')}}</el-checkbox>
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top:10px;">
                                <el-button class="moreBtn" size="small" icon="iconfont icon-24px" circle @click="moreFrom" style="margin-right:0;"></el-button>
                                <el-button type="primary" size="small" plain @click="searchFrom">{{$t('SEARCH')}}</el-button>
                                <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;" @click="addInfo">{{$t('ADD')}}</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu" >
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="editInfo(row,index,size,type)" v-if="row.Status==0?true:false" ></el-button>
                        <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" @click="delInfo(row,index)" v-if="row.Status==0?true:false"></el-button>
                        <el-button icon="el-icon-refresh-left" type="text" size="medium" :title="$t('RecoverData')" v-if="row.Status==1?true:false" @click="recover(row,index)" ></el-button>
                    </template>

                    <template slot="expand" slot-scope="props">
                        <el-table :data="props.row.ContractDetails" style="width: 100%" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index"></el-table-column>
                            <el-table-column prop="Code" :label="$t('ProductCode')"></el-table-column>
                            <el-table-column prop="ProductName" :label="$t('Product Name')"></el-table-column>
                            <el-table-column prop="Number" :label="$t('Number')"></el-table-column>
                            <el-table-column prop="UnitName" :label="$t('UNIT')"></el-table-column>
                            <el-table-column prop="Color" :label="$t('COLOR')"></el-table-column>
                            <el-table-column prop="Remark" :label="$t('REMARK')"></el-table-column>
                        </el-table>
                    </template>
                </avue-crud>
            </template>
        </basic-container>

    </div>
</template>
<style scoped>
    .othChe .el-checkbox__label {
        color: #409eff !important;
    }

    .dataicon i {
        display: none;
    }
    .el-icon-arrow-right:before {
        content: "\E6E0";
        font-weight: bold;
        color: Tomato;
        font-size: 15px;
    }
</style>
<script>
    import { listInfo, delHead, recoverHead, POListById} from "@/api/contract";
    import { ProcessDropDownList, TracingDropDownList } from "@/api/unified";

    export default {
        data() {
            return {
                contractNoVal: "",
                categoryVal: "",
                productVal: "",
                beginTimeVal: "",
                endTimeVal: "",
                brandVal: "",
                codetVal: "",
                cooperativeVal: {
                    ids: [],
                },
                StatusVal: false,
                cooperativeData: [],//合作商
                tableData: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
       

            }
        },
        created() {
            this.init()
        },
        watch: {
            $route(to, from) {
                this.init()
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        delBtnText: this.$t('Delete'),
                        align: 'center',
                        columnBtn: false,
                        simplePage: true,
                        expand: true,
                        rowKey: 'Id',
                        expandRowKeys: [],
                        column: [
                            {
                                label: this.$t('ContractNo'),
                                prop: 'Code',
                            },
                            {
                                label: this.$t('ContractCategory'),
                                prop: 'Type',
                            },
                            {
                                label: this.$t('STATUS'),
                                type: 'select',
                                prop: 'Status',
                                dicData: [     // 下拉内容, 格式数组对象,  label: 显示内容  value: 值
                                    { label: this.$t('Delete'), value: 1 },
                                    { label: this.$t('normal'), value: 0 }
                                ],
                            },
                            {
                                label: this.$t('CooperativeCompany'),
                                prop: 'VendorCorporationId',
                                type: "select",
                                filterable: true,
                                dicData: this.cooperativeData,

                            },
                            {
                                label: this.$t('SigningTime'),
                                prop: 'SignDate',
                                type: "date",
                                format: 'yyyy-MM-dd'
                            },
                            {
                                label: this.$t('EffectiveStartDate'),
                                prop: 'BeginDate',
                                type: "date",
                                format: 'yyyy-MM-dd',
                            },
                            {
                                label: this.$t('EffectiveEndDate'),
                                prop: 'EndDate',
                                type: "date",
                                format: 'yyyy-MM-dd',
                            },
                            {
                                label: this.$t('REMARK'),
                                prop: 'Remark',
                            }
                        ]
                    }
                }
            }
        },
        methods: {
            //设置表头行的样式
            tableHeaderColor({ row, column, rowIndex, columnIndex }) {
                return 'background-color:#838bb3!important;padding: 0!important;'

            }, //表格行央视
            rowStyle({ row, column, rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            init() {
                this.getListInfo();
                TracingDropDownList('VendorList').then(res => {
                    this.cooperativeData = res.data;
                });

            },
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    }

                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.cooperativeVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                }
            },
            venchang() {
                if (this.cooperativeVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            moreFrom() {
                let fromHei = document.querySelector(".fromOutDiv").clientHeight;
                if (fromHei > 60) {
                    document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
                } else {
                    document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val
                this.getListInfo();
            },//搜索
            searchFrom() {
                this.getListInfo();
            },//新增
            addInfo() {
                this.$router.push({ path: '/pvh_factory/contractAdd', query: { sign: 'add', ID: '' } })
            },//删除数据
            delInfo(row, index) {
                if (row.Status == 1) { this.$message({ showClose: true, message: this.$t('NotCanDeleted'), type: "warning" }); return; }
                //如果使用的数据不能删除
                POListById(row.Id).then(res => {
                 var list = res.data
                 if (list.length > 0) { this.$message({ showClose: true, message: this.$t('NotCanDeletedContract'), type: "warning" }); return; }
                this.$confirm(this.$t('IsDel') + row.Code, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delHead(row.Id).then(res => {
                        this.getListInfo();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                        done();
                    });
                });
                })
            },//恢复数据
            recover(row, index) {
                this.$confirm(this.$t('IsRecover') + row.Code, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    recoverHead(row.Id).then(res => {
                        this.getListInfo();
                        this.$message({ showClose: true, message: this.$t('RecoverSuccess'), type: "success" });
                        done();
                    });
                })
            },//修改
            editInfo(row, index, size, type) {
                if (row.Status == 1) { this.$message({ showClose: true, message: this.$t('NotCanDeleted'), type: "warning" }); return; }
                this.$router.push({ path: '/pvh_factory/contractAdd', query: { sign: 'edit', ID: row.Id } })
            },//
            async getListInfo() {
                await listInfo(this.page.currentPage, this.page.pageSize, this.contractNoVal, this.cooperativeVal.ids.toString(), this.categoryVal, this.beginTimeVal, this.endTimeVal, this.productVal, this.brandVal, this.codetVal, '', this.StatusVal).then(res => {
                    this.tableData = res.data.Contracts;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro)
                })
            },
        }
    }
</script>